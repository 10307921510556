import { VStack, Text, Stack, FormControl, InputGroup, FormLabel, Input, InputRightElement, Button } from '@chakra-ui/react';
import * as React from 'react';

interface NewsletterFormProps {
	buttonColor?: string;
}

export const NewsletterForm: React.FC<NewsletterFormProps> = (props) => {
	const [email, setEmail] = React.useState('');
	const [status, setStatus] = React.useState<'success' | 'error'>();
	const [message, setMessage] = React.useState<string>();

	const subscribe = async (e) => {
		setMessage(undefined);
		e.preventDefault();

		try {
			const res = await fetch('./api/subscribe', {
				body: JSON.stringify({
					emailAddress: email,
				}),
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'post',
			});

			const data = await res.json();

			if (res.status === 200) {
				setMessage(data.message);
				setStatus('success');
			} else {
				setMessage(data.message);
				setStatus('error');
			}
		} catch (err) {
			setMessage('Something went wrong.');
		}
	};

	return (
		<VStack
			align="stretch"
			style={{
				color: 'initial',
			}}
		>
			<Text as="h2">Sign up for our newsletter — all fam, no spam.</Text>

			{status !== 'success' && (
				<form>
					<div>
						<Stack spacing={4}>
							<FormControl id="email">
								<InputGroup>
									<FormLabel hidden>Email</FormLabel>

									<Input onChange={(e) => setEmail(e.target.value)} placeholder="Email" pr={14} type="email" value={email} />

									<InputRightElement width="auto">
										<Button
											variant="pill"
											style={{
												color: props.buttonColor,
											}}
											type="submit"
											onClick={(e) => {
												e.preventDefault();
												subscribe(e);
											}}
										>
											Signup
										</Button>
									</InputRightElement>
								</InputGroup>
							</FormControl>
						</Stack>
					</div>
				</form>
			)}

			<div className="form-fields">
				{!!message && (
					<div
						className={status == 'success' ? 'm--success' : 'm--error'}
						id="newsletter_form_success"
						role={status == 'success' ? 'alert' : 'error'}
					>
						<p>{message}</p>
					</div>
				)}
			</div>
		</VStack>
	);
};

export default NewsletterForm;
