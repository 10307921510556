import { brandColors } from '@theme';
import { BCorpLogo } from './BCorp';
import { Grid8 } from './Grid';
import { Link } from '@chakra-ui/react';
import { SiteMetadata } from '@lib/api';
import { Stack, Box, Grid, Text, VStack, Link as ChakraLink, Flex, GridItem, UnorderedList, ListItem } from '@chakra-ui/react';
import * as React from 'react';
import dynamic from 'next/dynamic';
import ExternalArrowLink from './ExternalArrowLink';
import NewsletterForm from './NewsletterForm';

const TimezoneCurrentTime = dynamic(() => import('@components/Location'), { ssr: false });

const brandColorsArray = Object.values(brandColors);

interface FooterProps {
	showBackground?: boolean;
	siteMeta: SiteMetadata;
}

export const FooterOfficeInfo: React.FC<{
	abbreviation: string;
	addressLines: string[];
	emailInquiries: string;
	phone: string;
	timezone: string;
}> = (props) => {
	return (
		<Stack direction={{ base: 'row', sm: 'column' }} spacing={2} align="stretch">
			<Box flex={1}>
				<Text
					as="h3"
					fontSize={{ base: 'md', sm: 'lg', md: '2xl', lg: '3xl' }}
					fontWeight={{ base: 'bold', sm: 'normal' }}
					lineHeight="none"
					noOfLines={2}
				>
					<div>{props.abbreviation}→</div>
					<TimezoneCurrentTime timezone={props.timezone} />
				</Text>
			</Box>
			<Box flex={1} overflow="hidden">
				<VStack spacing={4} align="stretch">
					<Box>
						<>
							{props.addressLines[0]}
							<br />
							{props.addressLines[1]}
							<br />
							{props.addressLines[2]}
						</>{' '}
						<ExternalArrowLink href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(props.addressLines.join(', '))}`} />
					</Box>
					<Box as="ul">
						<ChakraLink href={`mailto:${props.emailInquiries}`}>{props.emailInquiries}</ChakraLink>
						<br />
						<ChakraLink href={`tel:${props.phone}`} isExternal>
							{props.phone}
						</ChakraLink>
					</Box>
				</VStack>
			</Box>
		</Stack>
	);
};

export const Footer: React.FC<FooterProps> = (props) => {
	const [background, setBackground] = React.useState<string>(brandColorsArray[0]);

	React.useEffect(() => {
		const randomIndex = Math.floor(Math.random() * brandColorsArray.length);
		const backgroundColor = brandColorsArray[randomIndex];
		setBackground(backgroundColor);
	}, []);

	const footerSocialNetworks = props.siteMeta.site.socialNetworks.filter((socialNetwork) => socialNetwork.name !== 'Vimeo');

	const columns = 8;
	const offices = props.siteMeta.site.offices;

	return (
		<Flex
			as="footer"
			flexDirection="column"
			minHeight="100vh"
			justifyContent="space-between"
			role="contentinfo"
			backgroundColor={background ?? 'brand.gray'}
			transition="background-color 0.75s ease"
			style={{ backgroundColor: !props.showBackground ? 'transparent' : '' }}
			position="relative"
			zIndex={{ base: 'overlay', sm: 'unset' }}
		>
			<Grid8 rowGap={10}>
				{props.siteMeta.site.offices.map((office, i) => (
					<GridItem key={office._id} colSpan={{ base: 8, sm: 4, lg: i % 4 === 2 ? 3 : 2 }} colStart={{ sm: 'auto' }}>
						<FooterOfficeInfo
							abbreviation={office.abbreviation}
							addressLines={[office.address.line1, office.address.line2, office.address.line3]}
							emailInquiries={office.emailInquiries}
							phone={office.phone}
							timezone={office.timezone}
						/>
					</GridItem>
				))}
			</Grid8>

			<VStack align="stretch" flex="1" justify="center">
				<Grid8 rowGap={10}>
					<GridItem
						colSpan={{ base: 8, sm: 6, md: 5, lg: 4 }}
						colStart={{ base: 0 }}
						order={{ base: 1, lg: 0 }}
						style={{
							color: background,
						}}
					>
						<NewsletterForm buttonColor={background} />
					</GridItem>

					<GridItem colSpan={{ base: 3, md: 2 }} display={{ base: 'none', lg: 'initial' }} />

					{/* <GridItem colSpan={{ base: 8, sm: 6, lg: 2 }} colStart={{ base: 0, sm: 0, lg: 'auto' }}>
						<UnorderedList styleType="none" ml={0}>
							{props.siteMeta.site.socialNetworks.map((socialNetwork) => {
								return (
									<ListItem key={socialNetwork._key}>
										<ChakraLink href={socialNetwork.url} isExternal>
											{socialNetwork.name}
										</ChakraLink>
									</ListItem>
								);
							})}
						</UnorderedList>
					</GridItem> */}
				</Grid8>
			</VStack>

			<Grid8 alignItems="end">
				{/* <GridItem colSpan={{ base: 8, sm: 2 }} colStart={{ base: 1 }}>
					<Link href="/feedback">Feedback?</Link>
				</GridItem> */}

				<GridItem colSpan={{ base: 8, sm: 2 }} colStart={{ base: 1 }}>
					BUCK is in{' '}
					<Link href="https://residence.co">
						<u>Residence</u>
					</Link>
					.
				</GridItem>

				<GridItem colSpan={{ base: 8, sm: 3 }} colStart={{ base: 1 }}>
					© {props.siteMeta.site.copyrightYear} {props.siteMeta.site.title}. All Rights Reserved.
				</GridItem>

				<GridItem colSpan={{ base: 8, sm: 2 }} colStart={{ base: 1 }}>
					<Stack direction="row" spacing={4} as={UnorderedList} styleType="none" ml={0}>
						{footerSocialNetworks.map((socialNetwork) => {
							return (
								<ListItem key={socialNetwork._key} mb={0}>
									<ChakraLink href={socialNetwork.url} isExternal>
										{socialNetwork.name}
									</ChakraLink>
								</ListItem>
							);
						})}
					</Stack>
				</GridItem>

				<GridItem colSpan={{ base: 8, sm: 1 }} colStart={{ base: 1 }}>
					<BCorpLogo />
				</GridItem>
			</Grid8>
		</Flex>
	);
};

export default Footer;
